@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600|Source+Code+Pro:300,400,500");
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?imgwca");
  src: url("fonts/icomoon.eot?imgwca#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?imgwca") format("truetype"), url("fonts/icomoon.woff?imgwca") format("woff"), url("fonts/icomoon.svg?imgwca#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-email:before {
  content: "\e902"; }

.icon-facebook:before {
  content: "\e903"; }

.icon-github:before {
  content: "\e900"; }

.icon-linkedin:before {
  content: "\e901"; }

@-webkit-keyframes showFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes showFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes showFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes showFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes hideFade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes hideFade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes hideFade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes hideFade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.25); }
  100% {
    opacity: 1; } }

@-moz-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.25); }
  100% {
    opacity: 1; } }

@-ms-keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.25); }
  100% {
    opacity: 1; } }

@keyframes showContent {
  0% {
    opacity: 0;
    transform: scale(0.25); }
  100% {
    opacity: 1; } }

@-webkit-keyframes starsBgHome {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-moz-keyframes starsBgHome {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-ms-keyframes starsBgHome {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@keyframes starsBgHome {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-webkit-keyframes starMotionHome1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-moz-keyframes starMotionHome1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-ms-keyframes starMotionHome1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@keyframes starMotionHome1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-webkit-keyframes starMotionHome2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-moz-keyframes starMotionHome2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-ms-keyframes starMotionHome2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@keyframes starMotionHome2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-webkit-keyframes starsBgWork {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-moz-keyframes starsBgWork {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-ms-keyframes starsBgWork {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@keyframes starsBgWork {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-webkit-keyframes starMotionWork1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-moz-keyframes starMotionWork1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-ms-keyframes starMotionWork1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@keyframes starMotionWork1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-webkit-keyframes starMotionWork2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-moz-keyframes starMotionWork2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-ms-keyframes starMotionWork2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@keyframes starMotionWork2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-webkit-keyframes starsBgAbout {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-moz-keyframes starsBgAbout {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-ms-keyframes starsBgAbout {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@keyframes starsBgAbout {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-webkit-keyframes starMotionAbout1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-moz-keyframes starMotionAbout1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-ms-keyframes starMotionAbout1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@keyframes starMotionAbout1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-webkit-keyframes starMotionAbout2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-moz-keyframes starMotionAbout2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-ms-keyframes starMotionAbout2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@keyframes starMotionAbout2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-webkit-keyframes starsBgContact {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-moz-keyframes starsBgContact {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-ms-keyframes starsBgContact {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@keyframes starsBgContact {
  0% {
    transform: scale(1); }
  40% {
    transform: scale(0.95); } }

@-webkit-keyframes starMotionContact1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-moz-keyframes starMotionContact1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-ms-keyframes starMotionContact1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@keyframes starMotionContact1 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.15); }
  51% {
    opacity: 0;
    transform: scale(0.85); }
  100% {
    opacity: 1; } }

@-webkit-keyframes starMotionContact2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-moz-keyframes starMotionContact2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-ms-keyframes starMotionContact2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@keyframes starMotionContact2 {
  0% {
    opacity: 1;
    transform: scale(1); }
  50% {
    opacity: 0;
    transform: scale(1.5); }
  51% {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    opacity: 1; } }

@-webkit-keyframes animScroll1 {
  0% {
    opacity: 0.15; }
  10% {
    opacity: 1; }
  55% {
    opacity: 0.15; } }

@-moz-keyframes animScroll1 {
  0% {
    opacity: 0.15; }
  10% {
    opacity: 1; }
  55% {
    opacity: 0.15; } }

@-ms-keyframes animScroll1 {
  0% {
    opacity: 0.15; }
  10% {
    opacity: 1; }
  55% {
    opacity: 0.15; } }

@keyframes animScroll1 {
  0% {
    opacity: 0.15; }
  10% {
    opacity: 1; }
  55% {
    opacity: 0.15; } }

@-webkit-keyframes animScroll2 {
  0% {
    opacity: 0.15; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 1; }
  75% {
    opacity: 0.15; } }

@-moz-keyframes animScroll2 {
  0% {
    opacity: 0.15; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 1; }
  75% {
    opacity: 0.15; } }

@-ms-keyframes animScroll2 {
  0% {
    opacity: 0.15; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 1; }
  75% {
    opacity: 0.15; } }

@keyframes animScroll2 {
  0% {
    opacity: 0.15; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 1; }
  75% {
    opacity: 0.15; } }

@-webkit-keyframes animScroll3 {
  0% {
    opacity: 0.15; }
  40% {
    opacity: 0.25; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.15; } }

@-moz-keyframes animScroll3 {
  0% {
    opacity: 0.15; }
  40% {
    opacity: 0.25; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.15; } }

@-ms-keyframes animScroll3 {
  0% {
    opacity: 0.15; }
  40% {
    opacity: 0.25; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.15; } }

@keyframes animScroll3 {
  0% {
    opacity: 0.15; }
  40% {
    opacity: 0.25; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.15; } }

.btn {
  border: 0.15em solid #0e417b;
  background: transparent;
  border-radius: 0.26em;
  padding: 0.85em 2.26em;
  color: #0e417b;
  box-shadow: 0 5px 10px rgba(14, 65, 123, 0.15);
  font-size: 1em;
  font-family: 'Source Code Pro';
  letter-spacing: -0.026em;
  transition: background ease 0.33s, box-shadow ease 0.33s, color ease 0.33s;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  cursor: pointer; }

@media (max-width: 35.5em) {
  .btn {
    min-width: 50%; } }

.btn:hover {
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(14, 65, 123, 0.175);
  cursor: pointer; }

.btn:active {
  box-shadow: none; }

.btn.btn-primary {
  background: #0e417b;
  border-color: #0e417b;
  color: white;
  box-shadow: 0 5px 10px rgba(14, 65, 123, 0.25); }

.btn.btn-primary:hover {
  color: #0e417b;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(14, 65, 123, 0.175); }

.btn.btn-primary-light {
  background: #1359a9;
  border-color: #1359a9;
  color: white;
  box-shadow: 0 5px 10px rgba(19, 89, 169, 0.25); }

.btn.btn-primary-light:hover {
  color: #1359a9;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(19, 89, 169, 0.175); }

.btn.btn-primary-dark {
  background: #051529;
  border-color: #051529;
  color: white;
  box-shadow: 0 5px 10px rgba(5, 21, 41, 0.25); }

.btn.btn-primary-dark:hover {
  color: #051529;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(5, 21, 41, 0.175); }

.btn.btn-secondary {
  background: #84A98C;
  border-color: #84A98C;
  color: white;
  box-shadow: 0 5px 10px rgba(132, 169, 140, 0.25); }

.btn.btn-secondary:hover {
  color: #84A98C;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(132, 169, 140, 0.175); }

.btn.btn-accent {
  background: #FE5F55;
  border-color: #FE5F55;
  color: white;
  box-shadow: 0 5px 10px rgba(254, 95, 85, 0.25); }

.btn.btn-accent:hover {
  color: #FE5F55;
  background: transparent;
  box-shadow: 0 6px 0 -3px rgba(254, 95, 85, 0.25); }

.btn.btn-accent-light {
  background: #fe776e;
  border-color: #fe776e;
  color: white;
  box-shadow: 0 5px 10px rgba(254, 119, 110, 0.25); }

.btn.btn-accent-light:hover {
  color: #fe776e;
  background: transparent;
  box-shadow: 0 6px 0 -3px rgba(254, 119, 110, 0.25); }

.btn.btn-accent-dark {
  background: #fe473c;
  border-color: #fe473c;
  color: white;
  box-shadow: 0 5px 10px rgba(254, 71, 60, 0.25); }

.btn.btn-accent-dark:hover {
  color: #fe473c;
  background: transparent;
  box-shadow: 0 6px 0 -3px rgba(254, 71, 60, 0.25); }

.btn.btn-tertiary {
  background: #c55c3b;
  border-color: #c55c3b;
  color: white;
  box-shadow: 0 5px 10px rgba(197, 92, 59, 0.25); }

.btn.btn-tertiary:hover {
  color: #c55c3b;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(197, 92, 59, 0.175); }

.btn.btn-quaternary {
  background: #1985A1;
  border-color: #1985A1;
  color: white;
  box-shadow: 0 5px 10px rgba(25, 133, 161, 0.25); }

.btn.btn-quaternary:hover {
  color: #1985A1;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(25, 133, 161, 0.175); }

.btn.btn-quinary {
  background: #49b360;
  border-color: #49b360;
  color: white;
  box-shadow: 0 5px 10px rgba(73, 179, 96, 0.25); }

.btn.btn-quinary:hover {
  color: #49b360;
  background: transparent;
  box-shadow: 0 10px 0 -6px rgba(73, 179, 96, 0.175); }

* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  padding: 0;
  margin: 0;
  background: #181b1e;
  font-size: calc(14px + 0.15vh);
  font-family: 'Source Sans Pro', sans-serif;
  color: #181b1e;
  font-weight: 400; }

@media (max-width: 64em) {
  body {
    font-size: calc(13px + 0.15vh); } }

@media (max-width: 48em) {
  body {
    font-size: 14px; } }

@media (max-width: 35.5em) {
  body {
    font-size: 12px; } }

@media (max-width: 20em) {
  body {
    font-size: 11px; } }

a {
  color: #FE5F55;
  text-decoration: none;
  transition: color ease 0.33s, border ease 0.33s; }

a:hover {
  color: white; }

input, textarea {
  border-radius: 0; }

ul, li {
  margin: 0;
  padding: 0;
  list-style: none; }

h1, h2, h3, h4, h5 {
  font-family: 'Source Code Pro';
  font-weight: 300;
  letter-spacing: -0.026em;
  color: #FE5F55; }

h1.sans, h2.sans, h3.sans, h4.sans, h5.sans {
  font-family: 'Source Sans Pro';
  letter-spacing: 0; }

h1 a, h2 a, h3 a, h4 a, h5 a {
  border-bottom: 3px dashed rgba(254, 95, 85, 0.67); }

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover {
  border-bottom: 3px solid #FE5F55; }

h1 {
  font-size: 3.33em;
  font-weight: 500; }

h2 {
  font-size: 3em; }

h3 {
  font-size: 2.5em; }

header {
  padding: 2.5em 0 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }

@media (max-width: 48em) {
  header {
    z-index: 4; } }

header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 2.5em);
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  z-index: -1;
  pointer-events: none; }

.wrap-logo {
  float: left;
  color: #DCDCDD;
  margin: 0 0 0 6.67%; }

@media (max-width: 35.5em) {
  .wrap-logo {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 1.15em; } }

@media (max-width: 20em) {
  .wrap-logo {
    font-size: 1em; } }

.wrap-logo h1 {
  margin: 0;
  font-size: 2em;
  font-weight: 400;
  transition: text-shadow ease 0.15s; }

.wrap-logo h1:hover {
  text-shadow: 0 0 0.5em #FE5F55, 0 1px 2px black, 0 2px 4px black; }

.wrap-logo h1:hover span:first-child {
  color: #FE5F55; }

.wrap-logo h1:hover span:last-child {
  color: #DCDCDD; }

.wrap-logo span {
  transition: color ease 0.25s; }

.wrap-logo span:first-child {
  margin-right: 0.25em;
  color: #DCDCDD; }

.wrap-logo span:last-child {
  color: #FE5F55; }

nav {
  float: right;
  padding: 1em 6.67% 0.67em 0;
  position: relative; }

@media (max-width: 48em) {
  nav {
    padding-right: 1.67em; } }

@media (max-width: 35.5em) {
  nav {
    padding: 0;
    position: fixed;
    font-size: 1.25em;
    bottom: 0;
    width: 100vw;
    text-align: center;
    background: rgba(254, 95, 85, 0.975);
    box-shadow: 0 -0.15em 0.33em rgba(0, 0, 0, 0.25);
    z-index: 5; } }

nav a {
  padding: 1.25em 1.5em;
  color: white;
  text-transform: uppercase;
  font-family: 'Source Code Pro';
  letter-spacing: 1.5px;
  font-weight: 500; }

nav a:hover {
  color: #FE5F55; }

nav a:last-child {
  margin-right: -1.5em; }

@media (max-width: 35.5em) {
  nav a {
    padding: 1.25em 0 1.5em;
    width: 33.33vw;
    float: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 1.15em; } }

.loading {
  position: fixed;
  z-index: 10;
  color: #FE5F55;
  text-align: center;
  font-size: 3em;
  width: 100%;
  margin-top: 25%;
  font-family: 'Source Sans Pro', sans-serif; }

.app-inner {
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto; }

@media (max-width: 48em) {
  .app-inner {
    min-height: 100%; } }

.work .app-inner {
  overflow-y: hidden; }

@media (max-width: 920px) {
  .work .app-inner {
    overflow-y: auto; } }

.wrap-inner {
  max-width: 64rem;
  margin: 0 auto; }

.bg-wrap {
  height: 100vh;
  width: 100vw;
  z-index: 0; }

@media (max-width: 64em) {
  .bg-wrap {
    height: 100%; } }

@media (max-width: 48em) {
  .bg-wrap {
    height: 100%; } }

.bg-wrap .bg {
  content: "";
  position: absolute;
  height: 100vh;
  width: 100vw; }

@media (max-width: 48em) {
  .bg-wrap .bg {
    height: 100%; } }

.bg-wrap .bg:after {
  content: "";
  position: absolute;
  top: -5em;
  left: -5em;
  min-height: calc(100vh + 10em);
  width: calc(100vw + 10em);
  background-size: cover;
  background-position: center center;
  background-color: #181b1e;
  background-image: url("../images/bg-space.jpg");
  filter: brightness(0.25);
  opacity: 0.85; }

@media (max-width: 48em) {
  .bg-wrap .bg:after {
    min-height: 80em; } }

@media (max-width: 35.5em) {
  .bg-wrap .bg:after {
    min-height: 72em; } }

.anim-scroll {
  display: block;
  text-align: center;
  width: 100%; }

.anim-scroll > span {
  content: "";
  width: 2px;
  margin: 0 auto;
  height: 0.75em;
  display: block;
  background: #FE5F55;
  text-align: center;
  opacity: 0.15;
  position: relative; }

.anim-scroll > span:nth-child(1) {
  animation: animScroll1 1.5s infinite;
  animation-delay: 1s; }

.anim-scroll > span:nth-child(2) {
  animation: animScroll2 1.5s infinite;
  animation-delay: 1s; }

.anim-scroll > span:nth-child(3) {
  animation: animScroll3 1.5s infinite;
  animation-delay: 1s; }

.anim-scroll > span:nth-child(3):before, .anim-scroll > span:nth-child(3):after {
  content: "";
  position: absolute;
  top: 100%;
  border-style: solid; }

.anim-scroll > span:nth-child(3):before {
  border-width: 6px;
  left: -5px;
  border-color: #181b1e transparent transparent;
  z-index: 1; }

.anim-scroll > span:nth-child(3):after {
  border-width: 9px;
  left: -8px;
  border-color: #FE5F55 transparent transparent; }

#home, #work, #about, #contact {
  width: 100%;
  position: relative !important; }

.home #wrap-home-top, .home #wrap-work-top, .home #wrap-about-top, .home #wrap-contact-top, .work #wrap-home-top, .work #wrap-work-top, .work #wrap-about-top, .work #wrap-contact-top, .about #wrap-home-top, .about #wrap-work-top, .about #wrap-about-top, .about #wrap-contact-top, .contact #wrap-home-top, .contact #wrap-work-top, .contact #wrap-about-top, .contact #wrap-contact-top {
  animation: showContent 0.5375s forwards ease; }

.home .bg-stars:after {
  animation: starsBgHome 0.75s forwards ease; }

.home .star-field.field-1, .home .star-field.field-2 {
  animation: starMotionHome1 0.625s forwards ease; }

.home .star-field.field-3, .home .star-field.field-4 {
  animation: starMotionHome2 0.5s forwards ease; }

.work .wrap-nav a:nth-child(1) {
  color: #FE5F55;
  cursor: default; }

@media (max-width: 35.5em) {
  .work .wrap-nav a:nth-child(1) {
    color: #010509;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25); } }

.work .bg-stars:after {
  position: fixed;
  animation: starsBgWork 0.75s forwards ease; }

.work .star-field.field-1, .work .star-field.field-2 {
  animation: starMotionWork1 0.625s forwards ease; }

.work .star-field.field-3, .work .star-field.field-4 {
  animation: starMotionWork2 0.5s forwards ease; }

.about .wrap-nav a:nth-child(2) {
  color: #FE5F55;
  cursor: default; }

@media (max-width: 35.5em) {
  .about .wrap-nav a:nth-child(2) {
    color: #010509;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25); } }

.about .bg-stars:after {
  animation: starsBgAbout 0.75s forwards ease; }

.about .star-field.field-1, .about .star-field.field-2 {
  animation: starMotionAbout1 0.625s forwards ease; }

.about .star-field.field-3, .about .star-field.field-4 {
  animation: starMotionAbout2 0.5s forwards ease; }

.contact .wrap-nav a:nth-child(3) {
  color: #FE5F55;
  cursor: default; }

@media (max-width: 35.5em) {
  .contact .wrap-nav a:nth-child(3) {
    color: #010509;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25); } }

.contact .bg-stars:after {
  animation: starsBgContact 0.75s forwards ease; }

.contact .star-field.field-1, .contact .star-field.field-2 {
  animation: starMotionContact1 0.625s forwards ease; }

.contact .star-field.field-3, .contact .star-field.field-4 {
  animation: starMotionContact2 0.5s forwards ease; }

.not-found {
  padding: 10em;
  text-align: center; }

.not-found h1 span {
  display: block;
  font-size: 3.33em; }

.sandbox {
  padding: 8.5em;
  text-align: center; }

.bg-wrap.layer {
  position: absolute !important; }

header,
.app-inner,
#wrap-home-top,
#wrap-home-bottom,
#wrap-about-top,
#wrap-contact-top {
  pointer-events: visible !important; }

._3eJOp {
  height: 100% !important;
  width: 100% !important;
  top: 0% !important;
  left: 0% !important; }

.star-field {
  position: absolute !important;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  pointer-events: none; }

.star-field.field-1, .star-field.field-2 {
  z-index: 0;
  opacity: 0.85; }

.star-field.field-3, .star-field.field-4 {
  z-index: 2;
  opacity: 0.85; }

.star-field.field-1 .star:nth-child(1) {
  left: 42vw;
  top: 40em;
  transform: scale(0.67);
  filter: blur(0.37em);
  opacity: 0.85; }

.star-field.field-1 .star:nth-child(1):after {
  transform: scale(0.75); }

.star-field.field-1 .star:nth-child(2) {
  right: 25vw;
  top: 18em;
  transform: scale(0.75);
  filter: blur(0.5em);
  opacity: 0.75; }

.star-field.field-1 .star:nth-child(2):after {
  transform: scale(0.75); }

.star-field.field-1 .star:nth-child(3) {
  left: 33vw;
  top: 10em;
  transform: scale(0.87);
  filter: blur(0.38em);
  opacity: 0.5; }

.star-field.field-1 .star:nth-child(3):after {
  transform: scale(0.925); }

.star-field.field-1 .star:nth-child(4) {
  left: 10vw;
  top: 48em;
  transform: scale(0.75);
  filter: blur(0.25em);
  opacity: 0.85; }

.star-field.field-1 .star:nth-child(4):after {
  transform: scale(0.85); }

.star-field.field-2 .star:nth-child(1) {
  right: 4vw;
  top: 22em;
  transform: scale(0.87);
  filter: blur(0.275em);
  opacity: 0.85; }

.star-field.field-2 .star:nth-child(1):after {
  transform: scale(0.925); }

.star-field.field-2 .star:nth-child(2) {
  left: 7vw;
  top: 12em;
  transform: scale(0.87);
  filter: blur(0.3em);
  opacity: 0.5; }

.star-field.field-2 .star:nth-child(2):after {
  transform: scale(0.925); }

.star-field.field-2 .star:nth-child(3) {
  right: 12vw;
  top: 42em;
  transform: scale(0.87);
  filter: blur(0.37em); }

.star-field.field-2 .star:nth-child(3):after {
  transform: scale(0.925); }

.star-field.field-2 .star:nth-child(4) {
  left: 50vw;
  top: 42em;
  transform: scale(0.87);
  filter: blur(0.45em);
  opacity: 0.5; }

.star-field.field-2 .star:nth-child(4):after {
  transform: scale(0.925); }

.star-field.field-3 .star:nth-child(1) {
  right: 43vw;
  top: 12em;
  transform: scale(0.67);
  filter: blur(0.105em);
  opacity: 0.75; }

.star-field.field-3 .star:nth-child(1):after {
  transform: scale(1); }

.star-field.field-3 .star:nth-child(2) {
  right: 5vw;
  top: 26em;
  transform: scale(0.75);
  filter: blur(0.15em);
  opacity: 0.85; }

.star-field.field-3 .star:nth-child(2):after {
  transform: scale(0.925);
  opacity: 0.1; }

.star-field.field-3 .star:nth-child(3) {
  left: 12vw;
  top: 30em;
  transform: scale(0.95);
  filter: blur(0.125em);
  opacity: 0.95; }

.star-field.field-3 .star:nth-child(3):after {
  transform: scale(0.925);
  opacity: 0.25; }

.star-field.field-3 .star:nth-child(4) {
  left: 76vw;
  top: 28em;
  transform: scale(0.5);
  filter: blur(0.15em);
  opacity: 0.5; }

.star-field.field-3 .star:nth-child(4):after {
  transform: scale(0.925);
  opacity: 0.85; }

.star-field.field-4 .star:nth-child(1) {
  right: 16vw;
  top: 38em;
  transform: scale(0.8);
  filter: blur(0.12em);
  opacity: 0.25; }

.star-field.field-4 .star:nth-child(1):after {
  transform: scale(0.85); }

.star-field.field-4 .star:nth-child(2) {
  right: 29vw;
  top: 14em;
  transform: scale(0.97);
  filter: blur(0.075em);
  opacity: 0.33; }

.star-field.field-4 .star:nth-child(2):after {
  transform: scale(0.925); }

.star-field.field-4 .star:nth-child(3) {
  right: 26vw;
  top: 52em;
  transform: scale(0.77);
  filter: blur(0.15em);
  opacity: 0.5; }

.star-field.field-4 .star:nth-child(3):after {
  transform: scale(1.25);
  opacity: 0.15; }

.star-field.field-4 .star:nth-child(4) {
  left: 11vw;
  top: 43em;
  transform: scale(0.57);
  filter: blur(0.25em);
  opacity: 0.75; }

.star-field.field-4 .star:nth-child(4):after {
  transform: scale(0.925); }

.star-field.field-4 .star:nth-child(5) {
  left: 50vw;
  top: 30em;
  transform: scale(0.77);
  filter: blur(0.21em);
  opacity: 0.85; }

.star-field.field-4 .star:nth-child(5):after {
  transform: scale(0.925);
  opacity: 0.5; }

.star-field .star {
  content: "";
  height: 0.575em;
  width: 0.575em;
  background: white;
  border-radius: 50%;
  position: absolute;
  z-index: 1; }

.star-field .star.ring:after {
  content: "";
  position: absolute;
  height: calc(100% + 2em - 1px);
  width: calc(100% + 2em - 1px);
  top: -1em;
  left: -1em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.75;
  filter: blur(0.25em); }

#wrap-home-top {
  overflow: hidden;
  width: 100vw;
  height: 100vh; }

@media (max-width: 48em) {
  #wrap-home-top {
    height: 100%;
    overflow: auto;
    min-height: 56.5em; } }

#wrap-home-top .home-intro {
  text-align: center;
  color: #DCDCDD;
  margin: 28vh auto 10vh;
  width: 100%;
  max-width: 64rem;
  left: 0;
  right: 0; }

@media (max-width: 48em) {
  #wrap-home-top .home-intro {
    margin: 12em auto 5em; } }

#wrap-home-top .home-intro h1 {
  font-size: 5em;
  margin: 0.5em;
  color: white; }

@media (max-width: 64em) {
  #wrap-home-top .home-intro h1 {
    font-size: 4.5em; } }

@media (max-width: 48em) {
  #wrap-home-top .home-intro h1 {
    font-size: 4em; } }

@media (max-width: 35.5em) {
  #wrap-home-top .home-intro h1 {
    font-size: 3.5em; } }

#wrap-home-top .btn {
  margin: 3.33em auto;
  font-size: 1.5em;
  display: inline-block; }

#wrap-home-bottom {
  position: relative; }

#wrap-home-bottom:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  min-height: calc(100vh + 10em);
  width: 100vw;
  background-size: cover;
  background-position: center center;
  background-color: #181b1e;
  background-image: url("../images/bg-space.jpg");
  filter: brightness(0.25);
  opacity: 1;
  transform: rotate(180deg) scaleX(-1);
  z-index: -1; }

#wrap-home-bottom .wrap-inner {
  padding: 2em 4em 3.33em;
  background: linear-gradient(white 50%, #ffe7e6);
  position: relative;
  font-size: 2.15em;
  text-align: center;
  z-index: 2;
  display: block;
  width: calc(100% - 3em);
  margin: 0 auto;
  border-radius: 0.8658em;
  filter: drop-shadow(0 0 1.5em rgba(254, 95, 85, 0.15)) drop-shadow(0 0 3em rgba(254, 95, 85, 0.15)) drop-shadow(0 1.5em 2em rgba(254, 95, 85, 0.15));
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.5); }

@media (max-width: 48em) {
  #wrap-home-bottom .wrap-inner {
    padding: 3em;
    min-height: 0; } }

@media (max-width: 35.5em) {
  #wrap-home-bottom .wrap-inner {
    padding: 1.5em 1.25em 2.25em;
    width: calc(100% - 2em); } }

#wrap-home-bottom .wrap-inner:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 1.5em;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  border-color: #ffe7e6 transparent transparent; }

#wrap-home-bottom .wrap-inner h2 {
  color: #FE5F55;
  font-size: 3.5em;
  margin: 0.25em 0;
  text-shadow: 0 2px 0 white; }

@media (max-width: 48em) {
  #wrap-home-bottom .wrap-inner h2 {
    font-size: 3em; } }

@media (max-width: 35.5em) {
  #wrap-home-bottom .wrap-inner h2 {
    font-size: 2.5em; } }

#wrap-home-bottom .wrap-inner h3 {
  font-size: 1.75em;
  font-weight: 100;
  margin: 0.75em 0 1em;
  text-shadow: 0 1px 0 white;
  color: #444444; }

@media (max-width: 48em) {
  #wrap-home-bottom .wrap-inner h3 {
    font-size: 1.5em; } }

@media (max-width: 35.5em) {
  #wrap-home-bottom .wrap-inner h3 {
    font-size: 1em; } }

#wrap-home-bottom .wrap-inner .btn {
  font-size: 0.67em;
  margin: 1em;
  display: inline-block; }

@media (max-width: 48em) {
  #wrap-home-bottom .wrap-inner .btn {
    font-size: 0.67em; } }

@media (max-width: 35.5em) {
  #wrap-home-bottom .wrap-inner .btn {
    font-size: 0.575em;
    margin: 0.75em auto;
    width: 100%; } }

#wrap-home-portrait {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center; }

#wrap-home-portrait:after {
  content: "";
  position: absolute;
  top: -5em;
  left: -5em;
  min-height: calc(100vh + 10em);
  width: calc(100vw + 10em);
  background-size: cover;
  background-position: center center;
  background-color: #181b1e;
  background-image: url("../images/bg-space.jpg");
  filter: brightness(0.25);
  opacity: 1;
  z-index: -1; }

#wrap-home-portrait .portrait-wrap {
  position: relative;
  z-index: 3;
  padding: 5em 0 0; }

@media (max-width: 48em) {
  #wrap-home-portrait .portrait-wrap {
    padding-top: 3em;
    padding-bottom: 3em; } }

@media (max-width: 35.5em) {
  #wrap-home-portrait .portrait-wrap {
    padding-top: 1.5em;
    padding-bottom: 0;
    margin: -6em 0; } }

#wrap-home-portrait .portrait-wrap canvas {
  transform: scale(0.925);
  transform-origin: center bottom;
  cursor: pointer; }

@media (max-width: 35.5em) {
  #wrap-home-portrait .portrait-wrap canvas {
    transform: scale(1); } }

.home #controlKit {
  z-index: -1;
  position: fixed;
  opacity: 0; }

.work.lightbox .app-inner {
  z-index: 10; }

#work {
  z-index: 3; }

#wrap-work-top {
  overflow: hidden;
  width: 100vw;
  height: 100vh; }

@media (max-width: 920px) {
  #wrap-work-top {
    height: 100%;
    width: 100%;
    overflow: auto; } }

#wrap-work-top.true .SectionContainer {
  transition-duration: 0.5s !important; }

#wrap-work-top .SectionContainer {
  text-align: center;
  z-index: 1;
  color: #DCDCDD;
  height: 100%;
  width: 100%;
  padding: 1.5em 0 0;
  transition-duration: 0s !important; }

#wrap-work-top .Navigation {
  pointer-events: auto; }

.work-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden; }

.work-item-wrap {
  padding: 0 5em;
  transform: translate3d(22vw, 100vh, 0) scale(0.5);
  opacity: 0.33;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  transform-origin: top center; }

@media (max-width: 64em) {
  .work-item-wrap {
    padding: 0 5em 0 1.25em;
    transform: none;
    opacity: 1; } }

.work-item-wrap.active {
  transform: none;
  opacity: 1;
  z-index: 1;
  pointer-events: visible;
  filter: none; }

.work-item-wrap.active .work-name {
  left: 0;
  transition: left ease 0.33s 0.335s; }

.work-item-wrap.active .work-name h3 > span {
  top: 0;
  transition: top ease 0.33s 0.375s; }

.work-item-wrap.active .work-name h4 > span {
  right: 0;
  transition: right ease 0.33s 0.5s; }

.work-item-wrap.active .work-img:before {
  left: 0;
  width: 100%;
  transition: left ease 0.5s 0.33s, width ease 0.5s 0.33s; }

.work-item {
  display: flex;
  padding: 0;
  margin: 0 auto;
  max-width: 75em;
  background: linear-gradient(160deg, transparent 33%, rgba(25, 133, 161, 0.5));
  box-shadow: 0 0.5em 0.75em rgba(0, 0, 0, 0.33);
  cursor: pointer;
  overflow: hidden;
  position: relative; }

@media (max-width: 64em) {
  .work-item {
    max-width: 72rem; } }

.work-name, .work-img {
  margin: 3em; }

.work-name {
  text-align: left;
  position: relative;
  left: -50%;
  transition: none;
  width: 40%;
  flex: 0.4; }

.work-name p {
  margin: 0;
  line-height: 1;
  color: #FE5F55;
  font-size: 1.25em;
  font-weight: 500;
  font-family: "Source Code Pro"; }

.work-name h3, .work-name h4 {
  color: white; }

.work-name h3 {
  margin: 0;
  padding: 0.15em 0 0.5em;
  font-size: 2.85em;
  font-weight: 400;
  line-height: 1.15;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.25);
  overflow: hidden; }

.work-name h3 > span {
  top: 5em;
  position: relative;
  transition: none; }

.work-name h4 {
  font-weight: 400;
  margin: 2.5em 0 2.5em 0.25em;
  padding: 0.33em 0 0.33em 2em;
  font-size: 1.125em;
  line-height: 1.67;
  border-left: 2px solid #FE5F55;
  overflow: hidden; }

.work-name h4 > span {
  right: calc(100% + 2.5em);
  position: relative;
  transition: none; }

.work-name .btn {
  margin: 0 0 2em 2.5em;
  display: inline-block;
  font-size: 1.15em; }

.work-img {
  position: relative;
  width: 60%;
  flex: 0.6; }

.work-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  height: 100%;
  width: 150%;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.33);
  background-size: cover;
  background-position: 10% 0;
  transition: left ease 0.33s 0.33s, width ease 0.33s 0.33s; }

.work-img.brendans-world:before {
  background-image: url("../images/work/brendans-world-1-min.jpg"); }

.work-img.nebula:before {
  background-image: url("../images/work/nebula-v1-1-min.jpg"); }

.work-img.milk:before {
  background-image: url("../images/work/milk-1-min.jpg"); }

.work-img.kourthouse:before {
  background-image: url("../images/work/kourthouse-1-min.jpg"); }

.work-img.greene-street:before {
  background-image: url("../images/work/greene-street-1-min.jpg"); }

.work-img.nebula-v5:before {
  background-image: url("../images/work/nebula-v5-1-min.jpg"); }

.work-img.ave-maria:before {
  background-image: url("../images/work/ave-maria-1-min.jpg"); }

.work-img.kidney:before {
  background-image: url("../images/work/kidney-1-min.jpg"); }

.work-img.spca:before {
  background-image: url("../images/work/spca-1-min.jpg"); }

.work-img.aha:before {
  background-image: url("../images/work/aha-1-min.jpg"); }

.work-img.wwo:before {
  background-image: url("../images/work/wwo-1-min.jpg"); }

.work-img.citadel:before {
  background-image: url("../images/work/citadel-1-min.jpg"); }

.work-img.eig:before {
  background-image: url("../images/work/eig-1-min.jpg"); }

.work-img.blackbaud:before {
  background-image: url("../images/work/blackbaud-1-min.jpg"); }

.work-img.bbcon:before {
  background-image: url("../images/work/bbcon-1-min.jpg"); }

.work-img.tom-carter:before {
  background-image: url("../images/work/tom-carter-1-min.jpg"); }

.work-img.mink:before {
  background-image: url("../images/work/mink-1-min.jpg"); }

.work-img.brendan-english:before {
  background-image: url("../images/work/brendan-english-1-min.jpg"); }

.work-img.carnegie:before {
  background-image: url("../images/work/carnegie-1-min.jpg"); }

.work-img.saylor:before {
  background-image: url("../images/work/saylor-1-min.jpg"); }

.work-img.campuspack:before {
  background-image: url("../images/work/campuspack-1-min.jpg"); }

.work-img.difference-engine:before {
  background-image: url("../images/work/de-1-min.jpg"); }

.work-img.delivery:before {
  background-image: url("../images/work/delivery-1-min.jpg"); }

.work-img.crew:before {
  background-image: url("../images/work/crew-1-min.jpg"); }

.work-img.learning-objects:before {
  background-image: url("../images/work/lo-1-min.jpg"); }

.work-img.lo-styleguide:before {
  background-image: url("../images/work/lo-style-1-min.jpg"); }

.work-img.resume:before {
  background-image: url("../images/work/resume-1-min.jpg"); }

.work-img.byu:before {
  background-image: url("../images/work/byu-1-min.jpg"); }

.work-img.tom-carter-v2:before {
  background-image: url("../images/work/tom-carter-v2-1-min.jpg"); }

.work-img.moodfood:before {
  background-image: url("../images/work/moodfood-1-min.jpg"); }

.work-img.socialbooth:before {
  background-image: url("../images/work/socialbooth-1-min.jpg"); }

.work-img.lisc:before {
  background-image: url("../images/work/lisc-1-min.jpg"); }

.work-img.quantum:before {
  background-image: url("../images/work/quantum-1-min.jpg"); }

.work-img.dates:before {
  background-image: url("../images/work/3dates-1-min.jpg"); }

.work-img.mink-v2:before {
  background-image: url("../images/work/mink-v2-1-min.jpg"); }

.work-img.ftpC:before {
  background-image: url("../images/work/ftpC-1-min.jpg"); }

.work-img.alluvion:before {
  background-image: url("../images/work/alluvion-1-min.jpg"); }

.work-img.fishbowl:before {
  background-image: url("../images/work/fishbowl-1-min.jpg"); }

.work-img.pierinasanchez:before {
  background-image: url("../images/work/pi-1-min.jpg"); }

.work-img.designgapp:before {
  background-image: url("../images/work/designgapp-1-min.jpg"); }

.work-img.ilc:before {
  background-image: url("../images/work/ilc-1.png"); }

.work-img.cadd:before {
  background-image: url("../images/work/cadd-1.png"); }

.work-img.acg:before {
  background-image: url("../images/work/acg-1.png"); }

.work-mobile {
  position: relative;
  top: 0;
  left: 0;
  margin: 8.5em 0 6.67em;
  height: 100%;
  width: 100%;
  overflow: auto;
  color: #DCDCDD;
  box-shadow: 0 -0.15em 1.25em #FE5F55, 0 -0.075em 0.15em rgba(0, 0, 0, 0.5); }

.work-mobile .work-item-wrap {
  transform: none;
  filter: none;
  padding: 0;
  position: relative;
  overflow: hidden; }

.work-mobile .work-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 -0.225em 0 #FE5F55 inset;
  background: linear-gradient(160deg, rgba(0, 0, 0, 0.33) 50%, rgba(15, 78, 95, 0.85)); }

.work-mobile .work-name {
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 7.5em 3.33em 4.5em;
  left: 0; }

@media (max-width: 35.5em) {
  .work-mobile .work-name {
    margin: 7.25em 2.5em 4.25em; } }

@media (max-width: 48em) {
  .work-mobile .work-name p {
    margin-bottom: 0.5em; } }

.work-mobile .work-name h3 {
  padding: 0;
  border: none;
  font-size: 2.25em;
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15); }

.work-mobile .work-img {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0; }

.work-mobile .work-img:before {
  height: 150%;
  width: 150%;
  z-index: 0;
  filter: grayscale(0.5) brightness(0.85);
  left: 0; }

.work-mobile .work-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(195deg, transparent, rgba(0, 0, 0, 0.85) 75%); }

.work .ril-caption {
  max-height: auto;
  display: none;
  background: rgba(0, 0, 0, 0.75); }

@media (max-width: 48em) {
  .work .ril-caption {
    display: block; } }

.work .ril-caption-content {
  padding: 1.5em 1.5em;
  font-size: 1.15em;
  line-height: 1.5; }

#wrap-about-top {
  overflow: hidden;
  width: 100vw;
  min-height: 100vh; }

@media (max-width: 48em) {
  #wrap-about-top {
    height: 100%;
    min-height: 50em; } }

@media print {
  #wrap-about-top {
    display: none; } }

#wrap-about-top .layer {
  min-height: 100vh; }

@media (max-width: 48em) {
  #wrap-about-top .layer {
    min-height: 50em; } }

#wrap-about-top .layer.resume-link {
  min-height: auto; }

.wrap-about-content {
  position: relative;
  z-index: 2;
  padding: 2.5em 6.67em;
  top: 48vh;
  margin: 0 auto;
  transform: translateY(-50%);
  text-align: center;
  max-width: 85rem; }

@media (max-width: 64em) {
  .wrap-about-content {
    font-size: 0.925em; } }

@media (max-width: 48em) {
  .wrap-about-content {
    padding: 2.5em 5em;
    top: 28em; } }

@media (max-width: 35.5em) {
  .wrap-about-content {
    font-size: 0.75em;
    padding: 3.33em; } }

.wrap-about-content h1, .wrap-about-content h2 {
  color: white; }

.wrap-about-content h1 {
  font-size: 4em;
  max-width: 30em; }

@media (max-width: 35.5em) {
  .wrap-about-content h1 {
    font-size: 4em;
    margin: 6em 0 0; } }

.wrap-about-content h1 > span {
  color: #FE5F55; }

.wrap-about-content h2 {
  font-size: 1.875em;
  margin: 2.5em auto 3.33em;
  line-height: 1.5;
  text-align: justify;
  max-width: 27em;
  padding: 0 1.5em; }

@media (max-width: 35.5em) {
  .wrap-about-content h2 {
    text-align: left;
    font-size: 2.25em; } }

@media (max-width: 35.5em) {
  .wrap-about-content h2 a {
    margin: 0 0.25em; } }

.wrap-about-content .btn {
  margin: 1em;
  font-size: 1.25em; }

.resume-link {
  position: absolute;
  margin-top: 85vh;
  left: 0;
  right: 0;
  font-size: 1.25em;
  width: 100%;
  text-align: center;
  color: #FE5F55; }

@media (max-width: 48em) {
  .resume-link {
    margin-top: 40em; } }

.resume-link > a {
  margin-left: 0.33em;
  border-bottom: 1px dashed rgba(254, 95, 85, 0.75); }

.resume-link > a:hover {
  border-bottom: 1px solid #FE5F55; }

.resume-link .anim-scroll {
  margin-top: 0.5em; }

#resume {
  padding: 2em;
  position: relative;
  font-size: 1.33em;
  text-align: left;
  z-index: 2;
  display: block;
  padding: 0;
  background: #efefef;
  box-shadow: 0 -0.5em 1.5em rgba(0, 0, 0, 0.5); }

@media (max-width: 48em) {
  #resume {
    font-size: 1.25em; } }

@media (max-width: 35.5em) {
  #resume {
    padding-bottom: 2em; } }

.resume-head {
  position: relative;
  z-index: 1;
  padding: 5.5em 5em;
  background-color: #181b1e;
  box-shadow: 0 0.75em 1.5em rgba(0, 0, 0, 0.33) inset, 0 0em 3em rgba(0, 0, 0, 0.25) inset;
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25); }

@media (max-width: 64em) {
  .resume-head {
    padding: 7.5em 4em 4.5em;
    font-size: 0.925em; } }

@media (max-width: 48em) {
  .resume-head {
    padding: 5em 2.25em 3.33em;
    text-align: center; } }

@media (max-width: 35.5em) {
  .resume-head {
    font-size: 0.85em; } }

@media print {
  .resume-head {
    text-align: left;
    padding: 2.5em; } }

.resume-head:before, .resume-head:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1; }

.resume-head:before {
  background-image: url(../images/bg-dc.jpg);
  background-position: center 33%;
  background-size: cover;
  filter: brightness(0.375) saturate(0.75) contrast(1.15); }

.resume-head:after {
  background: linear-gradient(transparent 15%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.25) 100%);
  box-shadow: 0 -0.25em 0 rgba(254, 95, 85, 0.67) inset; }

.resume-head a {
  color: white;
  margin: 0 0.15em;
  border-bottom: 1px dotted #FE5F55;
  transition: color ease 0.33s, border ease 0.33s; }

.resume-head a:hover {
  color: #FE5F55;
  border: 0 none; }

.resume-head .resume-img {
  height: 10em;
  width: 10em;
  overflow: hidden;
  border-radius: 50%;
  border: 0.15em solid white;
  display: inline-block;
  vertical-align: top;
  box-shadow: 0 0.15em 0.15em rgba(0, 0, 0, 0.25), 0 0 0 1px white inset; }

@media (max-width: 48em) {
  .resume-head .resume-img {
    font-size: 0.925em;
    display: block;
    margin: 0 auto; } }

@media print {
  .resume-head .resume-img {
    display: inline-block;
    height: 8em;
    width: 8em; } }

.resume-head .resume-img img {
  width: 100%;
  height: auto; }

.resume-head .resume-info {
  padding: 0.5em 0 0 3em;
  color: white;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 10em); }

@media (max-width: 48em) {
  .resume-head .resume-info {
    padding: 0.5em 0 0;
    width: 100%; } }

@media print {
  .resume-head .resume-info {
    width: calc(100% - 10em);
    padding: 0.5em 0 0 3em; } }

.resume-head .resume-info h3 {
  font-size: 2.5em;
  margin: 0.15em 0 0.33em; }

.resume-head .resume-info p {
  margin: 0.33em 0;
  font-weight: 300;
  font-size: 1em;
  color: #DCDCDD; }

.resume-head .resume-info .resume-role {
  font-size: 1.25em;
  font-weight: 400; }

@media print {
  .resume-head .resume-info .resume-role {
    font-size: 1em; } }

.resume-inner {
  padding: 3em 1.5em 1.5em;
  max-width: 68rem;
  margin: 0 auto; }

@media (max-width: 48em) {
  .resume-inner {
    padding: 3em 1em; } }

@media (max-width: 35.5em) {
  .resume-inner {
    padding: 2.5em 0.75em; } }

@media print {
  .resume-inner {
    padding: 3em; } }

.resume-inner h3 {
  font-size: 1.75em;
  margin: 0.25em 0; }

@media print {
  .resume-inner h3 {
    margin: 0; } }

.exp {
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background: #DCDCDD;
  display: inline-block; }

@media print {
  .exp {
    visibility: hidden; } }

.exp.exp1 {
  background: #38CDD8; }

.exp.exp2 {
  background: #8B60B4; }

.exp.exp3 {
  background: #F39B1E; }

.exp.exp4 {
  background: #85C226; }

.exp.exp5 {
  background: #CE3B19; }

.exp.exp6 {
  background: #5173E3; }

.exp.exp7 {
  background: #df279c; }

.resume-content {
  background: white;
  border-radius: 0.26em;
  margin: 1.5em 0 5em;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.125); }

@media (max-width: 48em) {
  .resume-content {
    margin: 1.5em 0 3.33em;
    overflow: hidden; } }

.resume-content a:hover {
  color: #0e417b; }

.resume-exp li {
  padding: 1.33em;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  line-height: 1;
  font-size: 0.85em;
  background: white;
  position: relative; }

.resume-exp li:nth-child(even) {
  background: #f6f6f6; }

.resume-exp li.active .exp:after {
  content: "-"; }

@media print {
  .resume-exp li .exp:after {
    display: none; } }

.resume-exp .exp {
  position: relative; }

.resume-exp .exp:after {
  content: "+";
  position: absolute;
  color: white;
  font-size: 1.25em;
  top: 0.085em;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: 'Source Code Pro'; }

.resume-exp .resume-content .resume-exp-item {
  cursor: pointer; }

.resume-exp .resume-content .resume-exp-item:before {
  content: "";
  position: absolute;
  top: 1.5em;
  left: 1.975em;
  width: 0.5em;
  height: calc(100% - 3em); }

@media print {
  .resume-exp .resume-content .resume-exp-item:before {
    width: 0.33em; } }

.resume-exp .resume-content .resume-exp-item.exp1:before {
  background: #38CDD8; }

.resume-exp .resume-content .resume-exp-item.exp2:before {
  background: #8B60B4; }

.resume-exp .resume-content .resume-exp-item.exp3:before {
  background: #F39B1E; }

.resume-exp .resume-content .resume-exp-item.exp4:before {
  background: #85C226; }

.resume-exp .resume-content .resume-exp-item.exp5:before {
  background: #CE3B19; }

.resume-exp .resume-content .resume-exp-item.exp6:before {
  background: #5173E3; }

.resume-exp .resume-content .resume-exp-item.exp7:before {
  background: #df279c; }

.resume-exp .resume-content .resume-exp-item.active .exp-description {
  padding: 1.5em 0 0.5em; }

@media print {
  .resume-exp .resume-content .resume-exp-item.active .exp-description {
    padding-top: 0.5em; } }

.resume-exp .resume-content .resume-exp-item.active .exp-description li {
  max-height: 100em;
  padding: 0.75em 1.5em 0.75em 0.25em;
  opacity: 1; }

.resume-exp .resume-content .resume-exp-item .exp-description {
  margin: 0 0.5em 0 3.1em;
  padding: 0;
  overflow: hidden;
  transition: padding ease 0.33s;
  width: calc(100% - 3.6em); }

@media print {
  .resume-exp .resume-content .resume-exp-item .exp-description {
    padding: 0.85em 0 0.5em;
    width: calc(100% - 4em);
    font-size: 0.925em; } }

.resume-exp .resume-content .resume-exp-item .exp-description li {
  background: none;
  padding: 0 0.5em;
  margin: 0 1.5em;
  line-height: 1.33;
  display: list-item;
  list-style-type: circle;
  max-height: 0;
  opacity: 0; }

@media print {
  .resume-exp .resume-content .resume-exp-item .exp-description li {
    max-height: 100em;
    padding: 0.25em 0.33em;
    opacity: 1;
    font-size: 0.825em;
    line-height: 1.425; } }

.resume-exp p {
  float: left;
  margin: 0.25em 1.5em; }

@media (max-width: 48em) {
  .resume-exp p {
    margin: 0.25em 1em; } }

@media print {
  .resume-exp p {
    font-size: 1.075em; } }

.resume-exp .exp {
  margin: 0.05em 0.15em;
  float: left;
  display: block; }

.resume-exp .duration {
  float: right;
  margin: 0.3em;
  font-size: 0.875em;
  line-height: 1.15;
  overflow: hidden; }

.resume-timeline {
  padding: 2em 2.5em;
  background: #181b1e; }

@media (max-width: 48em) {
  .resume-timeline {
    padding: 2em; } }

@media (max-width: 35.5em) {
  .resume-timeline {
    padding: 2em 1.5em 2.5em; } }

@media print {
  .resume-timeline {
    padding: 1.25em 2em 2em;
    margin-top: 1.5em; } }

.resume-timeline h3 {
  font-size: 1.25em;
  color: #DCDCDD; }

.experience-graph {
  margin-top: 1.5em;
  height: 15em; }

@media (max-width: 48em) {
  .experience-graph {
    height: 20em; } }

@media print {
  .experience-graph {
    height: 11em;
    margin-top: 0.5em; } }

.experience-graph text {
  fill: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.33); }

.experience-graph path {
  stroke: rgba(255, 255, 255, 0.25); }

@media print {
  .experience-graph svg {
    transform: scale(0.695);
    transform-origin: top left; } }

@media print {
  .resume-work {
    margin-top: 12rem;
    padding-top: 2rem; } }

.resume-work .resume-content {
  padding: 1.67em 2em 0;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  background: linear-gradient(175deg, white, #f6f6f6 50%);
  margin-bottom: 3em; }

@media (max-width: 48em) {
  .resume-work .resume-content {
    padding: 1.5em 1.5em 0; } }

@media (max-width: 35.5em) {
  .resume-work .resume-content {
    padding: 1.5em 1.25em 0; } }

@media print {
  .resume-work .resume-content {
    padding: 0.675em 1.5em; } }

.resume-work .resume-content:last-child {
  margin-bottom: 5em; }

@media print {
  .resume-work .resume-content:last-child {
    margin-bottom: 3em; } }

.resume-work .resume-content h4 {
  margin: 0 0 0.5em;
  padding: 0 0 0.5em;
  border-bottom: 1px dashed #DCDCDD;
  font-size: 1.33em;
  font-weight: 400;
  line-height: 1;
  width: 100%; }

@media print {
  .resume-work .resume-content h4 {
    margin: 0;
    padding: 0;
    border-color: #cfcfd0;
    font-size: 1.25em; } }

.resume-work .resume-content h4 a {
  border: none;
  display: inline-block; }

.resume-work .resume-content h4 img {
  max-width: 12em;
  max-height: 1.75em;
  margin: 0.25em 0;
  height: auto;
  width: auto; }

@media (max-width: 48em) {
  .resume-work .resume-content h4 img {
    max-width: 10em; } }

@media print {
  .resume-work .resume-content h4 img {
    max-width: 7.75em;
    max-height: 1.15em; } }

.resume-work .resume-content h4 span {
  float: right;
  opacity: 0.67;
  font-size: 0.75em;
  margin-top: 1.25em; }

@media (max-width: 48em) {
  .resume-work .resume-content h4 span {
    font-size: 0.575em;
    margin-top: 2em; } }

@media print {
  .resume-work .resume-content h4 span {
    margin-top: 1em; } }

.resume-work .resume-work-date {
  width: 7.5em;
  margin: 1em 0 0.5em;
  position: relative; }

@media (max-width: 48em) {
  .resume-work .resume-work-date {
    width: 100%;
    margin: 1em 0; } }

@media print {
  .resume-work .resume-work-date {
    width: 4.5em;
    margin: 0.75em 0; } }

.resume-work .resume-work-date:before {
  content: "";
  position: absolute;
  top: 0;
  left: 1.85em;
  width: 0.25em;
  background: linear-gradient(#9F1D2D 20%, #84A98C 50%);
  height: 100%;
  z-index: 0; }

@media (max-width: 48em) {
  .resume-work .resume-work-date:before {
    left: 0;
    top: 1.33em;
    width: 100%;
    height: 0.25em;
    background: linear-gradient(-90deg, #9F1D2D, #84A98C 50%); } }

@media print {
  .resume-work .resume-work-date:before {
    top: 0;
    left: 1.5em;
    width: 0.25em;
    height: 100%;
    background: linear-gradient(#9F1D2D, #84A98C 50%); } }

.resume-work .resume-work-date.today:before {
  background: #84A98C; }

.resume-work .resume-work-date.today .date:first-child {
  background: #417505;
  color: white;
  padding: 1em 0.25em; }

.resume-work .resume-work-date .date {
  background: white;
  padding: 0.5em 0.25em;
  font-size: 0.925em;
  font-weight: 400;
  border-radius: 0.39em;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
  width: 4.25em;
  text-align: center;
  margin: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1; }

@media (max-width: 48em) {
  .resume-work .resume-work-date .date {
    position: relative; } }

@media print {
  .resume-work .resume-work-date .date {
    position: absolute;
    font-size: 0.75em;
    box-shadow: none;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); } }

.resume-work .resume-work-date .date:nth-child(1) {
  top: 0; }

@media (max-width: 48em) {
  .resume-work .resume-work-date .date:nth-child(1) {
    float: right; } }

.resume-work .resume-work-date .date:nth-child(1) > span {
  display: block;
  background: #9F1D2D; }

.resume-work .resume-work-date .date:nth-child(2) {
  bottom: 0; }

@media (max-width: 48em) {
  .resume-work .resume-work-date .date:nth-child(2) {
    float: left; } }

.resume-work .resume-work-date .date > span {
  display: block;
  background: #417505;
  width: calc(100% + 1em);
  font-size: 0.75em;
  padding: 0.25em;
  margin: -0.75em -0.5em 0.5em;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600; }

.resume-work .resume-work-role {
  width: calc(100% - 7.5em); }

@media (max-width: 48em) {
  .resume-work .resume-work-role {
    width: 100%; } }

@media print {
  .resume-work .resume-work-role {
    width: calc(100% - 4.5em); } }

.resume-work .resume-work-role h5 {
  font-size: 1.15em;
  font-weight: 400;
  margin: 0.67em 0 1em;
  color: #FE5F55; }

@media print {
  .resume-work .resume-work-role h5 {
    font-size: 1em; } }

.resume-work .resume-work-role p {
  font-size: 0.875em; }

@media print {
  .resume-work .resume-work-role p {
    font-size: 0.75em; } }

.resume-work .resume-work-role > ul {
  margin: 0.5em 0.5em 0.5em 1.5em; }

.resume-work .resume-work-role > ul li {
  list-style: disc;
  font-size: 0.8em;
  margin: 0.5em 0; }

@media print {
  .resume-work .resume-work-role > ul li {
    font-size: 0.67em; } }

.resume-work .resume-work-exp {
  padding: 0.15em 2em 1em;
  margin: 2.25em -2em 0;
  background: white;
  width: calc(100% + 4em); }

@media (max-width: 48em) {
  .resume-work .resume-work-exp {
    padding: 0 1.5em 1em;
    margin-top: 1.5em; } }

@media print {
  .resume-work .resume-work-exp {
    display: none; } }

.resume-work .resume-work-exp ul {
  margin: 1em 0 0;
  font-size: 0.6em; }

.resume-work .resume-work-exp li {
  float: left;
  margin: 0.75em 2.5em 0.75em 0.15em; }

@media (max-width: 48em) {
  .resume-work .resume-work-exp li {
    margin-right: 2em; } }

.resume-work .resume-work-exp .exp {
  margin: 0.425em 0.67em -0.425em 0; }

@media print {
  .resume-education {
    margin-top: 2.5rem; } }

.resume-education .resume-content {
  padding: 2em;
  display: flex;
  flex-flow: row nowrap; }

@media (max-width: 35.5em) {
  .resume-education .resume-content {
    padding: 1.5em;
    text-align: center;
    flex-flow: row wrap; } }

@media print {
  .resume-education .resume-content {
    font-size: 0.925rem; } }

.resume-education .education-logo {
  margin-right: 2em; }

@media (max-width: 35.5em) {
  .resume-education .education-logo {
    margin: 0 auto; } }

.resume-education .education-logo img {
  width: auto;
  margin-left: 0.5em;
  display: block;
  max-height: 5.5em; }

@media (max-width: 35.5em) {
  .resume-education .education-logo img {
    margin: auto;
    font-size: 0.925em;
    max-height: 6.67em; } }

.resume-education .education-info {
  font-size: 1.25em;
  margin-top: 0.25em; }

@media (max-width: 35.5em) {
  .resume-education .education-info {
    width: 100%; } }

.resume-education .education-info h4 {
  margin: 0.5em 0;
  font-weight: 400; }

.resume-education .education-info h5 {
  margin: 0.5em 0;
  color: #444444; }

.resume-pdf {
  padding: 0 0 6.67em;
  display: inline-block;
  width: 100%;
  text-align: center; }

@media print {
  .resume-pdf {
    display: none; } }

#wrap-contact-top {
  height: 100vh;
  overflow: hidden; }

@media (max-width: 48em) {
  #wrap-contact-top {
    overflow: auto;
    height: 100%; } }

.wrap-contact-content {
  position: relative;
  z-index: 2;
  top: 45vh;
  margin: 0 auto;
  transform: translateY(-50%);
  text-align: center; }

@media (max-width: 48em) {
  .wrap-contact-content {
    font-size: 0.925em;
    margin-top: 26em;
    top: auto; } }

.wrap-contact-content .contact-social {
  font-size: 3.33em;
  padding: 0.5em;
  margin-bottom: 0.15em; }

@media (max-width: 35.5em) {
  .wrap-contact-content .contact-social {
    margin-top: -0.5em;
    margin-bottom: 0.25em;
    font-size: 3em; } }

.wrap-contact-content .contact-social .icon {
  margin: 0.75em;
  color: #FE5F55;
  transition: color ease 0.33s; }

.wrap-contact-content .contact-social .icon.icon-email, .wrap-contact-content .contact-social .icon.icon-github {
  font-size: 0.925em; }

.wrap-contact-content .contact-social .icon:hover {
  color: #DCDCDD; }

.wrap-contact-content #contact-form {
  background: rgba(246, 246, 246, 0.925);
  display: inline-block;
  margin: 0 auto;
  font-size: 1.25em;
  padding: 1.85em 3em;
  position: relative;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.33);
  transition: padding ease 0.33s; }

@media (max-width: 35.5em) {
  .wrap-contact-content #contact-form {
    padding: 1em 2em;
    font-size: 1.33em; } }

.wrap-contact-content #contact-form:before {
  content: "";
  position: absolute;
  height: calc(100% + 0.5em);
  border-radius: 0.26em 0.26em 0 0;
  width: calc(100% - 1.5em);
  top: -0.5em;
  left: 0.75em;
  background: #FE5F55;
  z-index: -1;
  opacity: 1;
  transition: top ease 0.33s 0.33s, opacity ease 0.33s 0.33s; }

.wrap-contact-content #contact-form.sending button .submit-text {
  animation: hideFade 0.33s ease forwards; }

.wrap-contact-content #contact-form.sending button .submit-loading {
  animation: showFade 0.33s ease forwards; }

.wrap-contact-content #contact-form.sent {
  padding: 1.85em; }

@media (max-width: 35.5em) {
  .wrap-contact-content #contact-form.sent {
    padding: 1em; } }

.wrap-contact-content #contact-form.sent:before, .wrap-contact-content #contact-form.sent input, .wrap-contact-content #contact-form.sent textarea, .wrap-contact-content #contact-form.sent #submit {
  top: -36em;
  opacity: 0; }

.wrap-contact-content #contact-form.sent h2 {
  top: 22%;
  opacity: 1;
  z-index: 1; }

.wrap-contact-content #contact-form.sent p, .wrap-contact-content #contact-form.sent p.true {
  font-size: 0;
  opacity: 0; }

.wrap-contact-content #contact-form h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  color: #FE5F55;
  text-shadow: 0 1px 0 white;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: top ease 0.33s 0.33s, opacity ease 0.33s 0.33s; }

.wrap-contact-content #contact-form h2 > span {
  display: block;
  font-size: 0.5em;
  font-weight: 500;
  margin: 0.5em 0 0; }

.wrap-contact-content #contact-form p {
  color: #FE5F55;
  font-weight: 600;
  padding: 0 0 1em;
  font-size: 0;
  opacity: 0;
  transition: font ease 0.33s, opacity ease 0.33s; }

.wrap-contact-content #contact-form p.true {
  font-size: 1em;
  opacity: 1; }

.wrap-contact-content #contact-form input, .wrap-contact-content #contact-form textarea {
  display: block;
  width: 100%;
  font-size: 1em;
  padding: 1em;
  width: 21em;
  margin: 1em 0;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0.15em 0 0 #FE5F55 inset;
  font-family: 'Source Sans Pro';
  position: relative;
  top: 0;
  transition: top ease 0.33s 0.33s, opacity ease 0.33s 0.33s, box-shadow ease 0.33s; }

@media (max-width: 48em) {
  .wrap-contact-content #contact-form input, .wrap-contact-content #contact-form textarea {
    width: 20em; } }

@media (max-width: 35.5em) {
  .wrap-contact-content #contact-form input, .wrap-contact-content #contact-form textarea {
    width: 18em;
    font-size: 16px; } }

.wrap-contact-content #contact-form input:hover, .wrap-contact-content #contact-form textarea:hover {
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.15), 0.15em 0 0 #FE5F55 inset; }

.wrap-contact-content #contact-form input:focus, .wrap-contact-content #contact-form textarea:focus {
  border-color: #FE5F55;
  background: white;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0.25em 0 0 #0e417b inset; }

.wrap-contact-content #contact-form textarea {
  height: 7.5em; }

.wrap-contact-content #contact-form button .submit-text {
  position: relative;
  z-index: 1; }

.wrap-contact-content #contact-form button .submit-loading {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/loading.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10.5em;
  opacity: 0;
  pointer-events: none;
  z-index: 0; }

.wrap-contact-content #contact-form #submit {
  position: absolute;
  background: #FE5F55;
  border-radius: 0 0 0.26em 0.26em;
  border: none;
  width: calc(100% - 1.5em);
  left: 0.75em;
  top: 100%;
  margin: 0;
  padding: 1.15em;
  z-index: -1;
  color: white;
  transition: background ease 0.33s;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: top ease 0.33s 0.33s, opacity ease 0.33s 0.33s; }

.wrap-contact-content #contact-form #submit:hover {
  background: #fe2f22;
  cursor: pointer; }

@media print {
  html {
    background: #efefef; }
  body {
    font-size: 14px; }
  body.about {
    background: #efefef; }
  header {
    display: none; }
  .resume-content {
    box-shadow: none; } }
